import { render, staticRenderFns } from "./bubble-menu-link.vue?vue&type=template&id=6d3d5068&scoped=true&"
import script from "./bubble-menu-link.vue?vue&type=script&lang=ts&"
export * from "./bubble-menu-link.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3d5068",
  null
  
)

export default component.exports