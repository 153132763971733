
















import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import ToolbarButton from "@/app/shared/components/html-editor/toolbar/toolbar-button.vue";
import ToolbarGroup from "@/app/shared/components/html-editor/toolbar/toolbar-group.vue";
import BubbleMenuContainer from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-container.vue";
import Link from "@/app/shared/components/html-editor/link/link.vue";

@Component({
  components: {
    ToolbarButton,
    ToolbarGroup,
    BubbleMenuContainer,
    Link,
  },
})
export default class BubbleMenuLink extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private goToLink(): void {
    const attributes = this.editor?.getAttributes("link");
    if (attributes?.href) window.open(attributes.href);
  }
  private unlink(): void {
    this.editor?.chain().focus().unsetLink().blur().run();
  }
}
