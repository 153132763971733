const colors: Array<string> = [
  "rgb(0, 0, 0)",
  "rgb(153, 51, 0)",
  "rgb(51, 51, 0)",
  "rgb(0, 51, 0)",
  "rgb(0, 51, 102)",
  "rgb(0, 0, 128)",
  "rgb(51, 51, 153)",
  "rgb(51, 51, 51)",
  "rgb(128, 0, 0)",
  "rgb(255, 102, 0)",
  "rgb(128, 128, 0)",
  "rgb(0, 128, 0)",
  "rgb(0, 128, 128)",
  "rgb(0, 0, 255)",
  "rgb(102, 102, 153)",
  "rgb(122, 134, 154)",
  "rgb(255, 0, 0)",
  "rgb(255, 153, 0)",
  "rgb(153, 204, 0)",
  "rgb(51, 153, 102)",
  "rgb(51, 204, 204)",
  "rgb(51, 102, 255)",
  "rgb(128, 0, 128)",
  "rgb(165, 173, 186)",
  "rgb(255, 0, 255)",
  "rgb(255, 204, 0)",
  "rgb(255, 255, 0)",
  "rgb(0, 255, 0)",
  "rgb(0, 255, 255)",
  "rgb(0, 204, 255)",
  "rgb(153, 51, 102)",
  "rgb(193, 199, 208)",
  "rgb(255, 153, 204)",
  "rgb(255, 204, 153)",
  "rgb(255, 255, 153)",
  "rgb(204, 255, 204)",
  "rgb(204, 255, 255)",
  "rgb(153, 204, 255)",
  "rgb(204, 153, 255)",
  "rgb(255, 255, 255)",
];

export default colors;
