import { Command, Node, mergeAttributes } from "@tiptap/core";
import { TextSelection } from "prosemirror-state";

export interface InfoBlockOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands {
    infoBlock: {
      addInfoBlock: (infoBlockType: InfoType) => Command;
    };
  }
}

export enum InfoType {
  info = "Info",
  note = "Note",
  success = "Success",
  warning = "Warning",
  error = "Error",
}

export const InfoBlock = Node.create<InfoBlockOptions>({
  name: "infoBlock",

  group: "block",
  content: "block+",

  addAttributes() {
    return {
      "data-info-block": {
        default: InfoType.info,
      },
      "data-icon": {
        default: "true",
      },
      "data-focused": {
        default: "false",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-info-block]",
      },
    ];
  },

  renderHTML: ({ HTMLAttributes }) => {
    return ["div", mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      addInfoBlock:
        (infoBlockType: InfoType) =>
        ({ editor, dispatch, tr }) => {
          const type = editor.schema.nodes[this.name];

          const node = type.createAndFill({
            "data-info-block": infoBlockType,
          });

          if (node && dispatch) {
            const offset = tr.selection.anchor + 1;
            tr.replaceSelectionWith(node)
              .scrollIntoView()
              .setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () => {
        const { empty, $anchor } = this.editor.state.selection;
        const isAtStart = $anchor.pos === 1;

        if (!empty || $anchor.parent.type.name !== this.name) {
          return false;
        }

        if (isAtStart || !$anchor.parent.textContent.length) {
          return this.editor.commands.clearNodes();
        }

        return false;
      },
    };
  },

  addNodeView() {
    return ({
      editor,
      node,
      getPos,
      HTMLAttributes,
      decorations,
      extension,
    }) => {
      const dom = document.createElement("div");
      dom.className = "info-block";

      const setAttrs = (attrs: { [key: string]: any }) => {
        if (typeof getPos === "function") {
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(getPos(), undefined, attrs)
          );
          editor.commands.focus();
        }
      };

      const createContent = () => {
        const content = document.createElement("div");
        content.addEventListener("click", () => {
          setAttrs({
            ...HTMLAttributes,
            "data-focused": "true",
          });
        });

        const observer = new MutationObserver((mutations, observer) => {
          mutations.forEach((mutation) => {
            if (
              (mutation.target as HTMLElement).tagName.toLowerCase() ===
                "div" &&
              mutation.type === "childList" &&
              mutation.previousSibling
            ) {
              if (
                !this.editor.isActive(this.name) &&
                HTMLAttributes["data-focused"] === "true"
              ) {
                setAttrs({
                  ...HTMLAttributes,
                  "data-focused": "false",
                });

                observer.disconnect();
              }
            }
          });
        });

        observer.observe(content, {
          attributes: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
        });

        Object.keys(HTMLAttributes).forEach((key) => {
          content.setAttribute(key, HTMLAttributes[key]);
        });

        return content;
      };

      const createTooltip = () => {
        const tooltip = document.createElement("div");
        tooltip.className = "info-block__tooltip";
        const buttonTypes: Array<InfoType | "remove-emoji"> = [
          ...Object.values(InfoType),
          "remove-emoji",
        ];

        buttonTypes.forEach((type) => {
          const button = document.createElement("button");
          button.setAttribute(
            "data-active",
            (type === HTMLAttributes["data-info-block"] &&
              HTMLAttributes["data-icon"].toString() === "true") ||
              (HTMLAttributes["data-icon"].toString() === "false" &&
                type === "remove-emoji")
              ? "true"
              : "false"
          );
          button.innerHTML = `<span class="c-icon-${
            type === InfoType.info ? "info1" : type.toLowerCase()
          }">${
            type === "remove-emoji"
              ? '<span class="path1"></span><span class="path2"></span><span class="path3"></span>'
              : ""
          }</span>`;

          button.addEventListener("click", () => {
            const attrs =
              type === "remove-emoji"
                ? {
                    ...HTMLAttributes,
                    "data-icon": "false",
                  }
                : {
                    ...HTMLAttributes,
                    "data-icon": "true",
                    "data-info-block": type,
                  };

            setAttrs(attrs);
          });

          tooltip.appendChild(button);
        });

        const closeButton = document.createElement("button");
        closeButton.className = "info-block__tooltip-close";
        closeButton.innerHTML = `<span class="el-icon-close"></span>`;
        tooltip.appendChild(closeButton);

        closeButton.addEventListener("click", () => {
          setAttrs({
            ...HTMLAttributes,
            "data-focused": "false",
          });
        });

        return tooltip;
      };

      const content = createContent();
      const tooltip = createTooltip();

      dom.appendChild(content);
      dom.appendChild(tooltip);

      return {
        dom,
        contentDOM: content,
      };
    };
  },
});
