












































import { Component, Emit, Vue } from "vue-property-decorator";
import Dialog from "@/app/shared/components/dialog/dialog.vue";
import ImageSearchInMedia from "@/app/shared/components/html-editor/image/pages/image-search-in-media.vue";
import ImageAttchedToPage from "@/app/shared/components/html-editor/image/pages/images-attached-to-page.vue";

import FileModel from "@/app/shared/models/file.model";

enum Components {
  ImageSearchInMedia = "ImageSearchInMedia",
  ImageAttchedToPage = "ImageAttchedToPage",
}

@Component({
  components: {
    Dialog,
    ImageSearchInMedia,
    ImageAttchedToPage,
  },
})
export default class ImageUploadModal extends Vue {
  private dialogVisible = false;
  private selectedComponent: Components | null = Components.ImageAttchedToPage;
  private components = Components;
  private files: Array<FileModel> = [];

  private toggleModal(value = false): void {
    this.dialogVisible = value;
    if (!value) {
      this.close();
    }
  }

  private changeSelectedComponent(component: Components | null): void {
    this.selectedComponent = component;
  }

  @Emit()
  private insert(): Array<FileModel> {
    this.toggleModal(false);
    return this.files;
  }

  @Emit()
  private close() {
    return;
  }

  @Emit()
  private closed(): void {
    this.changeSelectedComponent(null);
    this.files = [];
  }

  open(): void {
    this.changeSelectedComponent(Components.ImageAttchedToPage);
  }
}
