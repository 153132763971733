

















import { Editor } from "@tiptap/vue-2";
import { Component, Vue, Prop } from "vue-property-decorator";

export enum BlockEnum {
  paragraph = "Paragraph",
  heading1 = "Heading 1",
  heading2 = "Heading 2",
  heading3 = "Heading 3",
  heading4 = "Heading 4",
  heading5 = "Heading 5",
  heading6 = "Heading 6",
  blockquote = "Quote",
}

interface BlockType {
  tag: string;
  action: () => void;
}

type BlockDictionary = { [key in BlockEnum]: BlockType };
type HeadingType = 1 | 2 | 3 | 4 | 5 | 6;

@Component
export default class Paragraph extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private blocks: BlockDictionary = {
    [BlockEnum.paragraph]: {
      tag: "p",
      action: () => this.putParagraph(),
    },
    [BlockEnum.heading1]: {
      tag: "h1",
      action: () => this.putHeading(1),
    },
    [BlockEnum.heading2]: {
      tag: "h2",
      action: () => this.putHeading(2),
    },
    [BlockEnum.heading3]: {
      tag: "h3",
      action: () => this.putHeading(3),
    },
    [BlockEnum.heading4]: {
      tag: "h4",
      action: () => this.putHeading(4),
    },
    [BlockEnum.heading5]: {
      tag: "h5",
      action: () => this.putHeading(5),
    },
    [BlockEnum.heading6]: {
      tag: "h6",
      action: () => this.putHeading(6),
    },
    [BlockEnum.blockquote]: {
      tag: "blockquote",
      action: () => this.putBlockquote(),
    },
  };

  get active(): BlockEnum | null {
    if (this.headingActive(1)) return BlockEnum.heading1;
    if (this.headingActive(2)) return BlockEnum.heading2;
    if (this.headingActive(3)) return BlockEnum.heading3;
    if (this.headingActive(4)) return BlockEnum.heading4;
    if (this.headingActive(5)) return BlockEnum.heading5;
    if (this.headingActive(6)) return BlockEnum.heading6;
    if (this.editor?.isActive("blockquote")) return BlockEnum.blockquote;
    return BlockEnum.paragraph;
  }

  private headingActive(level: number): boolean {
    return !!this.editor?.isActive("heading", { level });
  }

  private changeBlock(blockType: BlockType) {
    blockType.action();
  }

  private putParagraph(): void {
    this.editor?.chain().focus().setParagraph().run();
  }
  private putHeading(level: HeadingType): void {
    this.editor?.chain().focus().toggleHeading({ level }).run();
  }
  private putBlockquote(): void {
    this.editor?.chain().focus().toggleBlockquote().run();
  }
}
