


















































































import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext } from "vue-router";

import ImagePattern from "@/app/shared/components/html-editor/image/image-pattern";
import CreateFileWithNewName from "@/app/shared/utils/create-file-with-new-name";
import FilesDictionary from "@/app/domains/document/file-dictionary";

import HTMLEditor from "@/app/shared/components/html-editor/html-editor.vue";
import NotFoundRoute from "@/app/shared/views/not-found/not-found.route";
import {
  CreateDocumentRoute,
  EditDocumentRoute,
} from "@/app/domains/document/document.route";

import DocumentService from "@/app/shared/services/document.service";

import Languages, { LanguageEnum } from "@/app/shared/constants/languages";

import { Form } from "element-ui";
import { Validator } from "@/app/shared/validators/form-rules.types";

import FileModel from "@/app/shared/models/file.model";
import Project from "@/app/shared/models/project.model";
import Document from "@/app/domains/document/models/document.model";
import DocumentContent from "@/app/domains/document/models/document-content.model";
import SaveDocument from "@/app/domains/document/models/save-document.model";
import SavedDocument from "@/app/domains/document/models/saved-document.model";

type DocumentForm = { documentName: string; documentContent: string };

@Component({
  components: {
    HTMLEditor,
  },

  async beforeRouteUpdate(to, from, next): Promise<void> {
    const instance = this as EditDocument;
    // await instance.preventRedirect(next);
    // if (instance.changed) return;
    if (to.params.id) await instance.getData(parseInt(to.params.id));
    next();
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    (this as EditDocument).preventRedirect(next);
  },
})
export default class EditDocument extends Vue {
  $refs!: {
    HTMLEditor: HTMLEditor;
    form: Form;
  };

  private form: DocumentForm = {
    documentName: "",
    documentContent: "",
  };
  private formRules: Validator<Omit<DocumentForm, "documentContent">> = {
    documentName: [
      {
        required: true,
        message: "Please input name",
        trigger: ["change", "blur"],
      },
    ],
  };

  private document: Document | null = null;
  private selectedContent: DocumentContent | null = null;

  private language = LanguageEnum.English;
  private languages = Languages;

  private editMode = true;
  private changed = false;

  private get project(): Project {
    return this.$store.getters["projectsModule/getSelectedProject"];
  }

  private get toggleButtonText(): string {
    return this.editMode ? "Preview" : "Edit";
  }

  private markChanged(): void {
    this.changed = true;
  }

  private markUnchanged(): void {
    this.changed = false;
  }

  private changeLanguage(value: number): void {
    if (this.document?.contents) {
      const content = this.document?.contents.find((i) => i.language === value);
      if (content) {
        this.selectedContent = content;
        this.form.documentContent = content.content;
        return;
      }
    }
    this.selectedContent = null;
    this.form.documentContent = "";
  }

  private async preventRedirect(next: NavigationGuardNext<Vue>): Promise<void> {
    try {
      if (this.changed) {
        await this.$confirm(
          "All changes will not be saved. Are you sure you want to leave this page?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        this.markUnchanged();
      }
      next();
    } catch (error) {
      next(false);
      return;
    }

    if (this.changed) {
      return;
    }
  }

  private close(): void {
    this.$router.push(
      `/projects/${this.project?.id}/${this.$route.params?.id || ""}`
    );
  }

  //#region Images
  filesDictionary: FilesDictionary = new FilesDictionary();

  private insertImages(files: Array<FileModel>): void {
    let content = "";

    files.forEach((f) => {
      if (f.file) {
        if (this.filesDictionary.has(f.name)) {
          content += ImagePattern(this.filesDictionary.get(f.name).url);
        } else {
          const url = URL.createObjectURL(f.file);
          this.filesDictionary.set(f.name, {
            url,
            data: f,
            file: CreateFileWithNewName(f.file, url),
          });
          content += ImagePattern(url);
        }
      } else if (f.url) content += ImagePattern(f.url);
    });
    this.$refs.HTMLEditor.insertContent(content);
  }
  //#endregion

  public get isCreate(): boolean {
    return this.$route.name === CreateDocumentRoute.name;
  }

  private generateModel(): SaveDocument {
    return {
      language: this.language,
      documentName: this.form.documentName,
      documentContent: this.form.documentContent,
      documentId: this.document?.id || undefined,
      projectId: this.$route.params.projectId
        ? parseInt(this.$route.params.projectId)
        : undefined,
      parentId:
        this.isCreate && this.$route.params.parentId
          ? parseInt(this.$route.params.parentId)
          : undefined,
    };
  }

  private generateFormData(): FormData {
    const data: SaveDocument = this.generateModel();
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    this.filesDictionary.forEach((fileName) => {
      const item = this.filesDictionary.get(fileName);
      if (new RegExp(item.url).test(this.form.documentContent)) {
        if (item.file) {
          formData.append("files", item.file);
          URL.revokeObjectURL(item.url);
        }
      } else this.filesDictionary.delete(fileName);
    });
    for (var value of formData.values()) {
      console.log(value);
    }

    return formData;
  }

  private async publish(): Promise<void> {
    if (this.changed) {
      this.$message({
        message:
          "You have unsaved changes. Please save your data before publishing.",
        type: "warning",
      });
      return;
    }

    try {
      await this.$confirm(
        "Are you sure you want to publish the document?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
    } catch (error) {
      return;
    }

    try {
      if (this.document?.id) {
        await DocumentService.publishDocument({
          id: this.document.id,
          language: this.language,
        });
        this.document.isDraft = false;
        this.markUnchanged();
        this.$message({
          message: "Document has been successfully published",
          type: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  private showSuccessMessage(): void {
    this.$message({
      message: this.isCreate
        ? "Document has been successfully created"
        : "Document has been successfully updated",
      type: "success",
    });
  }

  private async save(): Promise<void> {
    try {
      await this.$refs.form.validate();
    } catch (error) {
      return;
    }

    try {
      const document = (
        await DocumentService.saveDocument(this.generateFormData())
      ).data;
      this.filesDictionary.reset();
      this.markUnchanged();
      this.showSuccessMessage();
      if (!document.id) return;
      if (this.isCreate) {
        await this.$router.push({
          name: EditDocumentRoute.name,
          params: { id: document.id.toString() },
        });
      }
      this.getData(document.id);
    } catch (error) {
      console.error(error);
    }
  }

  private assignData(document: SavedDocument): void {
    this.document = document;

    if (this.document?.contents?.length) {
      const engContent = this.document.contents.find(
        (d) => d.language === this.language
      );
      if (engContent) this.selectedContent = engContent;
    } else {
      // select default content model
      this.selectedContent = {
        documentId: document.id,
        content: "",
        language: this.languages[0].key,
        isPublished: false,
      };
    }
    this.form.documentName = this.document?.name || "";
    this.form.documentContent = this.selectedContent?.content || "";
  }

  private async getData(id: number): Promise<void> {
    try {
      const { data } = await DocumentService.getDocumentById(id);
      this.assignData(data);
      if (!data) {
        this.$router.push({ name: NotFoundRoute.name });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  created(): void {
    const { id } = this.$route.params;
    const { lang } = this.$route.query;
    console.log(this.$route.query);

    if (typeof lang === "string")
      this.language = parseInt(lang) || LanguageEnum.English;
    if (id) this.getData(parseInt(id));
  }
}
