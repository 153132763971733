






































import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import Dialog from "@/app/shared/components/dialog/dialog.vue";
import LinkAddress from "@/app/shared/components/html-editor/link/link-address.vue";
import LinkBrowse from "@/app/shared/components/html-editor/link/link-browse.vue";

enum Components {
  LinkAddress = "LinkAddress",
  LinkBrowse = "LinkBrowse",
}

@Component({
  components: {
    Dialog,
    LinkBrowse,
    LinkAddress,
  },
})
export default class Link extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private dialogVisible = false;
  private url = "";

  private selectedComponent: Components = Components.LinkAddress;
  private components = Components;

  setUrl(url: string): void {
    this.url = url;
  }

  private toggleModal(value = false): void {
    this.dialogVisible = value;
  }

  private changeSelectedComponent(component: Components): void {
    this.selectedComponent = component;
  }

  private insert(): void {
    this.toggleModal(false);
    this.editor?.chain().focus().setLink({ href: this.url }).blur().run();
  }

  private closed(): void {
    this.selectedComponent = Components.LinkAddress;
  }

  private editorSelection(): void {
    const href = this.editor?.getAttributes("link")?.href;
    this.url = href;
  }

  created(): void {
    this.editor?.on("selectionUpdate", this.editorSelection);
  }

  beforeDestroy(): void {
    this.editor?.off("selectionUpdate", this.editorSelection);
  }
}
