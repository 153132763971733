import FileToBase64 from "@/app/shared/utils/file-to-base64";
import DataUrlToFile from "@/app/shared/utils/data-url-to-file";

export default async (files: Array<File>): Promise<Array<File>> => {
  const result: Array<File> = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    result.push(DataUrlToFile(await FileToBase64(file), file.name));
  }
  return result;
};
