

















import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";

enum AdditionalOptionEnum {
  Rule = "Rule",
  Layout2 = "Layout2",
  Layout3 = "Layout3",
  Details = "Details",
}

interface AdditionalOptionType {
  icon: string;
  title: string;
}

type AdditionalOptionDictionary = {
  [key in AdditionalOptionEnum]: AdditionalOptionType;
};

@Component
export default class AdditionalOptions extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private options: AdditionalOptionDictionary = {
    [AdditionalOptionEnum.Rule]: {
      icon: "rule",
      title: "Horizontal rule",
    },
    [AdditionalOptionEnum.Layout2]: {
      icon: "rule",
      title: "Layout 2",
    },
    [AdditionalOptionEnum.Layout3]: {
      icon: "rule",
      title: "Layout 3",
    },
    [AdditionalOptionEnum.Details]: {
      icon: "rule",
      title: "Details",
    },
  };

  select(option: AdditionalOptionEnum): void {
    switch (option) {
      case AdditionalOptionEnum.Rule:
        this.editor?.chain().focus().setHorizontalRule().run();
        break;
      case AdditionalOptionEnum.Layout2:
        this.editor?.chain().focus().insertLayoutBlock({ cols: 2 }).run();
        break;
      case AdditionalOptionEnum.Layout3:
        this.editor?.chain().focus().insertLayoutBlock({ cols: 3 }).run();
        break;
      case AdditionalOptionEnum.Details:
        this.editor?.chain().focus().setDetails().run();
        break;

      default:
        break;
    }
  }
}
