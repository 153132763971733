import Image from "@tiptap/extension-image";
import { dropImagePlugin, UploadFn } from "./drop-image-plugin";

export class ImageAttributes {
  width?: string = "";
  height?: string = "";
  title?: string = "";
}

export const createImageExtension = (uploadFn: UploadFn) =>
  Image.extend({
    selectable: true,
    addAttributes() {
      return {
        src: {
          default: null,
        },
        width: {
          default: null,
        },
        height: {
          default: null,
        },
      };
    },

    addCommands(): any {
      return {
        addImage:
          (options: any) =>
          ({ commands }: any) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          },
      };
    },

    addProseMirrorPlugins() {
      return [dropImagePlugin(uploadFn)];
    },
  });
