import { Command, Mark } from '@tiptap/core';

export interface SupMarkOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands {
    SupMark: {
      /**
       * Set a sup mark
       */
      setSup: () => Command,
    }
  }
}

export const SupMark = Mark.create<SupMarkOptions>({
  name: "sup",

  parseHTML() {
    return [{ tag: "sup" }];
  },

  renderHTML() {
    return ["sup", 0];
  },

  addCommands() {
    return {
      setSup:
        () =>
        ({ commands }) => {
          return commands.toggleMark("sup");
        },
    };
  },
});
