
















import { Editor } from "@tiptap/vue-2";
import { Component, Vue, Prop } from "vue-property-decorator";

enum ActionType {
  Strikethrough = "Strikethrough",
  Subscript = "Subscript",
  Superscript = "Superscript",
  Monospace = "Monospace",
  Clear = "Clear formatting",
}

@Component
export default class Marks extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private types = ActionType;

  private select(type: ActionType): void {
    switch (type) {
      case ActionType.Strikethrough:
        this.editor?.chain().focus().toggleStrike().run();
        break;

      case ActionType.Subscript:
        this.editor?.chain().focus().setSub().run();
        break;

      case ActionType.Superscript:
        this.editor?.chain().focus().setSup().run();
        break;

      case ActionType.Monospace:
        if (this.editor?.isActive("code"))
          this.editor?.chain().focus().unsetCode().run();
        else this.editor?.chain().focus().setCode().run();
        break;

      case ActionType.Clear:
        this.editor?.chain().focus().unsetAllMarks().run();
        break;

      default:
        break;
    }
  }
}
