import { Extension } from "@tiptap/core";
import { LayoutBlock } from "@/app/shared/components/html-editor/layout/layout-block";
import { LayoutColumn } from "@/app/shared/components/html-editor/layout/layout-column";

const LayoutExtension = Extension.create({
  name: "layoutExtension",

  addExtensions() {
    return [LayoutBlock, LayoutColumn];
  },
});

export { LayoutExtension, LayoutBlock, LayoutColumn };
