






import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class LinkAddress extends Vue {
  @Prop({ type: String }) value?: string;

  @Emit()
  input(value: string): string {
    return value;
  }
}
