import { Command, Mark } from '@tiptap/core';

export interface SubMarkOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands {
    SubMark: {
      /**
       * Set a sub mark
       */
      setSub: () => Command,
    }
  }
}

export const SubMark = Mark.create<SubMarkOptions>({
  name: "sub",

  parseHTML() {
    return [{ tag: "sub" }];
  },

  renderHTML() {
    return ["sub", 0];
  },

  addCommands() {
    return {
      setSub:
        () =>
        ({ commands }) => {
          return commands.toggleMark("sub");
        },
    };
  },
});
