


























































































































































































import { Component, Model, Vue, Watch } from "vue-property-decorator";
import FileModel from "@/app/shared/models/file.model";

import { Editor, EditorContent } from "@tiptap/vue-2";
import { BubbleMenu } from "@/app/shared/components/html-editor/bubble-menu/extension/bubble-menu.component";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import LinkExtension from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

// custom extensions
import {
  createImageExtension,
  ImageAttributes,
} from "@/app/shared/components/html-editor/image/extension";
import { SubMark } from "@/app/shared/components/html-editor/marks/extensions/sub-mark";
import { SupMark } from "@/app/shared/components/html-editor/marks/extensions/sup-mark";
import { ColorMark } from "@/app/shared/components/html-editor/color-picker/color-mark";
import { InfoBlock } from "@/app/shared/components/html-editor/info/info-extension";
import { TextIndentExtension } from "@/app/shared/components/html-editor/indent/indent-extension";
import { LayoutExtension } from "@/app/shared/components/html-editor/layout/layout-extension";

import Block from "@/app/shared/components/html-editor/block/block.vue";
import Toolbar from "@/app/shared/components/html-editor/toolbar/toolbar.vue";
import ToolbarButton from "@/app/shared/components/html-editor/toolbar/toolbar-button.vue";
import ToolbarGroup from "@/app/shared/components/html-editor/toolbar/toolbar-group.vue";
import SymbolPicker from "@/app/shared/components/html-editor/symbol-picker/symbol-picker.vue";
import ColorPicker from "@/app/shared/components/html-editor/color-picker/color-picker.vue";
import Info from "@/app/shared/components/html-editor/info/info.vue";
import ImageUploadModal from "@/app/shared/components/html-editor/image/image-upload-modal.vue";
import Link from "@/app/shared/components/html-editor/link/link.vue";
import Marks from "@/app/shared/components/html-editor/marks/marks.vue";
import TablePicker from "@/app/shared/components/html-editor/table/table-picker.vue";
import AdditionalOptions from "@/app/shared/components/html-editor/additional-options/additional-options.vue";
import BubbleMenuFont from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-font.vue";
import BubbleMenuImage from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-image.vue";
import BubbleMenuTable from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-table.vue";
import BubbleMenuLink from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-link.vue";

import Details from "@/app/shared/components/html-editor/details/extension-details/tiptap-extension-details";
import DetailsSummary from "@/app/shared/components/html-editor/details/extension-details-summary/tiptap-extension-details-summary";
import DetailsContent from "@/app/shared/components/html-editor/details/extension-details-content/tiptap-extension-details-content";

enum BubbleToolbar {
  image = "image",
  link = "link",
  info = "info",
  table = "table",
  text = "text",
}

@Component({
  components: {
    EditorContent,
    BubbleMenu,
    Block,
    Toolbar,
    ToolbarGroup,
    ToolbarButton,
    SymbolPicker,
    ColorPicker,
    Info,
    ImageUploadModal,
    Link,
    Marks,
    TablePicker,
    AdditionalOptions,
    BubbleMenuFont,
    BubbleMenuImage,
    BubbleMenuTable,
    BubbleMenuLink,
  },
})
export default class TextEditor extends Vue {
  @Model("change", { default: "", type: String }) data!: string;

  @Watch("data")
  dataChange(newValue: string): void {
    this.setContent(newValue);
  }

  editor: Editor | null = null;

  selectedBubbleToolbar?: BubbleToolbar = BubbleToolbar.text;
  bubbleToolbar = BubbleToolbar;
  imageOptionsForm: ImageAttributes = new ImageAttributes();

  // #region document manipulation
  putBulletList(): void {
    this.editor?.chain().focus().toggleBulletList().run();
  }
  putOrderedList(): void {
    this.editor?.chain().focus().toggleOrderedList().run();
  }
  undo(): void {
    this.editor?.chain().focus().undo().run();
  }
  redo(): void {
    this.editor?.chain().focus().redo().run();
  }
  applyTextAlign(direction: "left" | "center" | "right" | "justify"): void {
    this.editor?.chain().focus().setTextAlign(direction).run();
  }

  bold(): void {
    this.editor?.chain().focus().toggleBold().run();
  }
  italic(): void {
    this.editor?.chain().focus().toggleItalic().run();
  }
  underline(): void {
    this.editor?.chain().focus().toggleUnderline().run();
  }

  indent(): void {
    if (
      this.editor?.isActive("bulletList") ||
      this.editor?.isActive("orderedList")
    ) {
      this.editor?.chain().focus().sinkListItem("listItem").run();
    } else {
      this.editor?.chain().focus().indent().run();
    }
  }

  outdent(): void {
    if (
      this.editor?.isActive("bulletList") ||
      this.editor?.isActive("orderedList")
    ) {
      this.editor?.chain().focus().liftListItem("listItem").run();
    } else {
      this.editor?.chain().focus().outdent().run();
    }
  }

  get moreIsActive(): boolean {
    return (
      this.editor?.isActive("strike") ||
      this.editor?.isActive("sub") ||
      this.editor?.isActive("sup") ||
      this.editor?.isActive("code") ||
      false
    );
  }
  // #endregion document manipulation

  private insertImages(files: Array<FileModel>): void {
    this.$emit("insertImages", files);
  }

  insertContent(content: string): void {
    this.editor?.commands.insertContent(content);
  }

  setContent(value: string): void {
    if (this.getContent() !== value) this.editor?.commands.setContent(value);
  }

  getContent(): string {
    return this.editor?.getHTML() || "";
  }

  // #region Image manipulations
  setSelectedBubbleToolbar(bubbleToolbarType?: BubbleToolbar): void {
    // stop triggering bubble menu
    if (this.selectedBubbleToolbar === bubbleToolbarType) return;
    this.selectedBubbleToolbar = bubbleToolbarType;
  }

  // Apply image options for image editing form
  setCurrentImageOptions(options: ImageAttributes): void {
    Object.keys(options).forEach((key: string) => {
      this.imageOptionsForm[key as keyof ImageAttributes] =
        options[key as keyof ImageAttributes];
    });
  }

  /** Show bubble-menu with image options if selected content is image */
  onSelectionUpdate(): void {
    if (this.editor?.isActive("link")) {
      this.setSelectedBubbleToolbar(BubbleToolbar.link);
    } else if (this.editor?.isActive("image")) {
      const nodeAttributes = this.editor?.getAttributes("image");
      this.setSelectedBubbleToolbar(BubbleToolbar.image);
      this.setCurrentImageOptions(nodeAttributes as ImageAttributes);
    } else if (this.editor?.isActive("table")) {
      this.setSelectedBubbleToolbar(BubbleToolbar.table);
    } else this.setSelectedBubbleToolbar(BubbleToolbar.text);

    // editor.commands.setNode('image', {'width': '500'})
    // editor.commands.setImage({src: 'image'});
    // editor.commands.updateAttributes('image', { width: '500' });
  }

  onUpdate(): void {
    this.$emit("change", this.getContent());
  }

  createEditor(): Editor {
    return new Editor({
      extensions: [
        StarterKit,
        Details.configure({
          persist: true,
          HTMLAttributes: {
            class: "details",
          },
        }),
        DetailsSummary,
        DetailsContent,
        TextAlign,
        Underline,
        Table.configure({
          resizable: true,
          lastColumnResizable: true,
          allowTableNodeSelection: true,
          cellMinWidth: 0,
        }),
        TableHeader,
        TableRow,
        TableCell,
        LinkExtension.configure({
          openOnClick: false,
        }),
        TextIndentExtension,
        ColorMark,
        SubMark,
        SupMark,
        // Highlight.configure({ multicolor: true }),
        createImageExtension((files) => this.insertImages(files)).configure({
          inline: true,
        }),
        InfoBlock,
        LayoutExtension,
      ],
      content: ``,

      onSelectionUpdate: this.onSelectionUpdate,
      onUpdate: this.onUpdate,
    });
  }

  mounted(): void {
    this.editor = this.createEditor();
    this.setContent(this.data);
  }

  beforeDestroy(): void {
    this.editor?.destroy();
  }
}
