




















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import BubbleMenuContainer from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-container.vue";
import ToolbarGroup from "@/app/shared/components/html-editor/toolbar/toolbar-group.vue";
import ToolbarButton from "@/app/shared/components/html-editor/toolbar/toolbar-button.vue";
import Block from "@/app/shared/components/html-editor/block/block.vue";
import ColorPicker from "@/app/shared/components/html-editor/color-picker/color-picker.vue";
import Link from "@/app/shared/components/html-editor/link/link.vue";
import Marks from "@/app/shared/components/html-editor/marks/marks.vue";

@Component({
  components: {
    ToolbarGroup,
    ToolbarButton,
    BubbleMenuContainer,
    Block,
    ColorPicker,
    Link,
    Marks,
  },
})
export default class FontBubbleMenu extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private applyTextAlign(
    direction: "left" | "center" | "right" | "justify"
  ): void {
    this.editor?.chain().focus().setTextAlign(direction).run();
  }

  private get moreIsActive(): boolean {
    return (
      this.editor?.isActive("strike") ||
      this.editor?.isActive("sub") ||
      this.editor?.isActive("sup") ||
      this.editor?.isActive("code") ||
      false
    );
  }
}
