import { Plugin } from "prosemirror-state";
import FileModel from "@/app/shared/models/file.model";
import DeepFilesCopy from "@/app/shared/utils/deep-files-copy";

export type UploadFn = (files: Array<FileModel>) => void;

export const dropImagePlugin = (upload: UploadFn): Plugin => {
  return new Plugin({
    props: {
      handlePaste(view, event /*, slice*/) {
        const files = Array.from(event.clipboardData?.items || [])
          .map((f) => f.getAsFile())
          .filter(Boolean);

        if (files.length) {
          DeepFilesCopy(files as Array<File>).then((copiedFiles) => {
            if (upload && copiedFiles.length)
              upload(
                copiedFiles.map((file) => ({
                  name: Date.now().toString() + Math.random() * 1000000,
                  file: file,
                }))
              );
          });
          return true;
        }
        return false;
      },
      handleDOMEvents: {
        drop: (view, event) => {
          const hasFiles =
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files.length;

          if (!hasFiles) {
            return false;
          }

          const files = Array.from(event.dataTransfer?.files ?? []).filter(
            (file) => /image/i.test(file.type)
          );

          if (files.length === 0) {
            return false;
          }

          event.preventDefault();

          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });
          if (!coordinates) return false;

          DeepFilesCopy(files).then((copiedFiles) => {
            if (upload)
              upload(
                copiedFiles.map((file) => ({ name: file.name, file: file }))
              );
          });

          return true;
        },
      },
    },
  });
};
