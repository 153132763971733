

















import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class UploadFile extends Vue {
  @Prop({ type: String, default: "" }) action!: string;
  @Prop({ type: Boolean, default: true }) autoUpload!: string;
  @Prop({ type: Object, default: () => ({}) }) headers!: any;
  @Prop({ type: String, default: ".jpg, .jpeg, .tiff, .png, .gif" })
  accpet!: string;

  @Emit()
  private upload(): void {
    return;
  }

  @Emit()
  private change(): void {
    return;
  }
}
