import { Node, mergeAttributes } from "@tiptap/core";

export interface DetailsContentOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: {
    [key: string]: any;
  };
}

const DetailsContent = Node.create<DetailsContentOptions>({
  name: "detailsContent",
  content: "block+",
  defining: true,
  selectable: false,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name,
      }),
      0,
    ];
  },
  addNodeView() {
    return ({ HTMLAttributes }) => {
      const dom = document.createElement("div");
      const attributes = mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        {
          "data-type": this.name,
          hidden: "hidden",
        }
      );
      Object.entries(attributes).forEach(([key, value]) =>
        dom.setAttribute(key, value)
      );
      dom.addEventListener("toggleDetailsContent", () => {
        dom.toggleAttribute("hidden");
      });
      return {
        dom,
        contentDOM: dom,
        ignoreMutation(mutation) {
          if (mutation.type === "selection") {
            return false;
          }
          return !dom.contains(mutation.target) || dom === mutation.target;
        },
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }
          return true;
        },
      };
    };
  },
});

export { DetailsContent, DetailsContent as default };
