



















import { Component, Vue, Model } from "vue-property-decorator";
import { ElUploadInternalFileDetail } from "element-ui/types/upload";
import FileModel from "@/app/shared/models/file.model";
import DeepFilesCopy from "@/app/shared/utils/deep-files-copy";
import UploadFile from "@/app/shared/components/html-editor/image/upload-file.vue";
import FilePreview from "@/app/shared/components/file-preview/file-preview.vue";

interface PreviewFile extends ElUploadInternalFileDetail, FileModel {
  previewUrl: string;
}

@Component({
  components: {
    UploadFile,
    FilePreview,
  },
})
export default class ImageAttchedToPage extends Vue {
  @Model("change") files!: Array<PreviewFile>;

  private filesDetails: Array<PreviewFile> = [];

  private change(fileDetails: PreviewFile): void {
    if (this.filesDetails.some((f) => f.name === fileDetails.name)) return;
    fileDetails.previewUrl = URL.createObjectURL(fileDetails.raw);
    this.filesDetails.push(fileDetails);
    this.emitFilesChange();
  }

  private deleteFile(fileDetails: PreviewFile): void {
    if (fileDetails.previewUrl) URL.revokeObjectURL(fileDetails.previewUrl);
    this.filesDetails = this.filesDetails.filter((f) => f !== fileDetails);
    this.emitFilesChange();
  }

  private async emitFilesChange(): Promise<void> {
    for (let i = 0; i < this.filesDetails.length; i++) {
      const f = this.filesDetails[i];
      f.file = (await DeepFilesCopy([f.raw]))[0];
    }
    this.$emit("change", this.filesDetails);
  }

  private previewFile(fileDetails: PreviewFile): void {
    window.open(fileDetails.previewUrl);
  }

  beforeDestroy(): void {
    this.filesDetails.forEach((f) => this.deleteFile(f));
  }
}
