import { Mark, mergeAttributes, Command } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands {
    "color-mark": {
      setColor: (color: string) => Command;
    };
  }
}

export const ColorMark = Mark.create({
  name: "color-mark",
  group: "inline",

  defaultOptions: {
    HTMLAttributes: {},
  },

  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => {
          return {
            color:
              element.getAttribute("data-font-color") || element.style.color,
          };
        },
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }
          return {
            "data-font-color": attributes.color,
            style: `color: ${attributes.color};`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands(): any {
    return {
      setColor:
        (color: string) =>
        ({ commands }: any) => {
          return commands.setMark("color-mark", { color });
        },
      unsetHighlight:
        () =>
        ({ commands }: any) => {
          return commands.unsetMark("color-mark");
        },
    };
  },
});
