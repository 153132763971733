




























import { Component, Vue, Model } from "vue-property-decorator";
import FilePreview from "@/app/shared/components/file-preview/file-preview.vue";
import DocumentFileService from "@/app/shared/services/document-file.service";
import ImageLoad from "@/app/shared/components/file-preview/image-load.vue";

import FileModel from "@/app/shared/models/file.model";

interface SelectedFile extends FileModel {
  selected?: boolean;
}

@Component({
  components: {
    FilePreview,
    ImageLoad,
  },
})
export default class ImageSearchInMedia extends Vue {
  @Model("change") files!: Array<FileModel>;

  filterValue = "";
  loadedFiles: Array<SelectedFile> = [];
  selectedFiles: Array<SelectedFile> = [];

  private selectImage(file: SelectedFile): void {
    if (file.selected) {
      file.selected = false;
      this.selectedFiles = this.files.filter((f) => f.url !== file.url);
    } else {
      file.selected = true;
      this.selectedFiles.push(file);
    }
    this.emitFilesChange();
  }

  private emitFilesChange(): void {
    this.$emit("change", this.selectedFiles);
  }

  private previewFile(file: FileModel): void {
    window.open(file.url);
  }

  async created(): Promise<void> {
    try {
      const resp = await DocumentFileService.getFiles();
      this.loadedFiles = resp.data.map((f) => {
        (f as SelectedFile).selected = false;
        return f;
      });
    } catch (error) {
      console.error(error);
    }
  }
}
