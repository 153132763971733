import { ElUploadInternalFileDetail } from "element-ui/types/upload";
import FileModel from "@/app/shared/models/file.model";

type FileDictionaryValue = {
  url: string;
  data: ElUploadInternalFileDetail | FileModel;
  file: Blob | string;
};

type FileDictionary = {
  [key: string]: FileDictionaryValue;
};

export default class FilesDictionary {
  private _filesDictionary: FileDictionary = {};

  public get files(): FileDictionary {
    return this._filesDictionary;
  }

  public has(key: string): boolean {
    return key in this._filesDictionary;
  }

  public get(key: string): FileDictionaryValue {
    return this._filesDictionary[key] || {};
  }

  public set(key: string, value: FileDictionaryValue): void {
    this._filesDictionary[key] = value;
  }

  public delete(key: string): void {
    delete this._filesDictionary[key];
  }

  public add(key: string, data: FileDictionaryValue): void {
    if (this._filesDictionary[key]) return;
    this._filesDictionary[key] = data;
  }

  public reset(): void {
    this._filesDictionary = {};
  }

  public forEach(callback: (...args: any[]) => void): void {
    Object.keys(this._filesDictionary).forEach(callback);
  }
}
