const symbols: Array<string> = [
  " ",
  "&",
  '"',
  "¢",
  "€",
  "£",
  "¥",
  "©",
  "®",
  "™",
  "‰",
  "µ",
  "·",
  "•",
  "…",
  "′",
  "″",
  "§",
  "¶",
  "ß",
  "‹",
  "›",
  "«",
  "»",
  "‘",
  "’",
  "“",
  "”",
  "‚",
  "„",
  "<",
  ">",
  "≤",
  "≥",
  "–",
  "—",
  "¯",
  "‾",
  "¤",
  "¦",
  "¨",
  "¡",
  "¿",
  "ˆ",
  "˜",
  "°",
  "−",
  "±",
  "÷",
  "⁄",
  "×",
  "¹",
  "²",
  "³",
  "¼",
  "½",
  "¾",
  "ƒ",
  "∫",
  "∑",
  "∞",
  "√",
  "≈",
  "≠",
  "≡",
  "∏",
  "¬",
  "∩",
  "∂",
  "´",
  "¸",
  "ª",
  "º",
  "†",
  "‡",
  "À",
  "Á",
  "Â",
  "Ã",
  "Ä",
  "Å",
  "Æ",
  "Ç",
  "È",
  "É",
  "Ê",
  "Ë",
  "Ì",
  "Í",
  "Î",
  "Ï",
  "Ð",
  "Ñ",
  "Ò",
  "Ó",
  "Ô",
  "Õ",
  "Ö",
  "Ø",
  "Œ",
  "Š",
  "Ù",
  "Ú",
  "Û",
  "Ü",
  "Ý",
  "Ÿ",
  "Þ",
  "à",
  "á",
  "â",
  "ã",
  "ä",
  "å",
  "æ",
  "ç",
  "è",
  "é",
  "ê",
  "ë",
  "ì",
  "í",
  "î",
  "ï",
  "ð",
  "ñ",
  "ò",
  "ó",
  "ô",
  "õ",
  "ö",
  "ø",
  "œ",
  "š",
  "ù",
  "ú",
  "û",
  "ü",
  "ý",
  "þ",
  "ÿ",
  "Α",
  "Β",
  "Γ",
  "Δ",
  "Ε",
  "Ζ",
  "Η",
  "Θ",
  "Ι",
  "Κ",
  "Λ",
  "Μ",
  "Ν",
  "Ξ",
  "Ο",
  "Π",
  "Ρ",
  "Σ",
  "Τ",
  "Υ",
  "Φ",
  "Χ",
  "Ψ",
  "Ω",
  "α",
  "β",
  "γ",
  "δ",
  "ε",
  "ζ",
  "η",
  "θ",
  "ι",
  "κ",
  "λ",
  "μ",
  "ν",
  "ξ",
  "ο",
  "π",
  "ρ",
  "ς",
  "σ",
  "τ",
  "υ",
  "φ",
  "χ",
  "ψ",
  "ω",
  "←",
  "↑",
  "→",
  "↓",
  "↔",
  "◊",
  "♣",
  "♥",
  "♦",
];

export default symbols;
