import { Command, Node, mergeAttributes } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands {
    layoutColumn: {
      insertLayoutColumn: () => Command;
    };
  }
}

export interface LayoutColumnOptions {
  HTMLAttributes: Record<string, any>;
}

export const LayoutColumn = Node.create<LayoutColumnOptions>({
  name: "layoutColumn",
  content: "block+",
  layoutRole: "layoutColumn",
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: "div.layout-column" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "layout-column",
      }),
      0,
    ];
  },
});
