



























import { Component, Emit, Vue } from "vue-property-decorator";

const defaultTable: Array<Array<number>> = [
  [1, 1, 1, 0, 0],
  [1, 1, 1, 0, 0],
  [0, 0, 0, 0, 0],
];

@Component
export default class Table extends Vue {
  private table: Array<Array<number>> = [];

  private columns = 5;
  private rows = 3;

  get previewColumns(): number {
    return this.columns + 1;
  }

  get previewRows(): number {
    return this.rows + 1;
  }

  private cellSize = 24;
  private minRowsCount = 3;
  private maxRowsCount = 20;
  private minColumnsCount = 5;
  private maxColumnsCount = 15;

  private move(event: MouseEvent): void {
    const columnsCount = this.table[0].length;
    const columnsWidth = columnsCount * this.cellSize;
    const rowsCount = this.table.length;
    const rowsHeight = rowsCount * this.cellSize;

    // add new column
    if (event.offsetX >= columnsWidth && columnsCount < this.maxColumnsCount) {
      this.table.forEach((row) => {
        row.push(0);
      });
    }

    // remove unused column
    if (
      columnsWidth - event.offsetX > this.cellSize &&
      columnsCount > this.minColumnsCount
    ) {
      let removeCount = Math.floor(
        (columnsWidth - event.offsetX) / this.cellSize
      );
      removeCount =
        columnsCount - removeCount < this.minColumnsCount
          ? -(columnsCount - this.minColumnsCount)
          : -removeCount;
      this.table.forEach((row) => {
        row.splice(removeCount);
      });
    }

    // add new row
    if (event.offsetY >= rowsHeight && rowsCount < this.maxRowsCount) {
      this.table.push([...this.table[this.table.length - 1]]);
    }

    // remove unused row
    if (
      rowsHeight - event.offsetY > this.cellSize &&
      rowsCount > this.minRowsCount
    ) {
      let removeCount = Math.floor(
        (rowsHeight - event.offsetY) / this.cellSize
      );
      removeCount =
        rowsCount - removeCount < this.minRowsCount
          ? -(rowsCount - this.minRowsCount)
          : -removeCount;
      this.table.splice(removeCount);
    }

    this.syncTableSize(
      Math.floor(event.offsetX / this.cellSize),
      Math.floor(event.offsetY / this.cellSize)
    );

    this.updateCellValues();
  }

  private updateCellValues(): void {
    for (let rowIndex = 0; rowIndex < this.table.length; rowIndex++) {
      for (
        let columnIndex = 0;
        columnIndex < this.table[rowIndex].length;
        columnIndex++
      ) {
        if (columnIndex <= this.columns && rowIndex <= this.rows) {
          this.table[rowIndex][columnIndex] = 1;
          this.$set(this.table[rowIndex], columnIndex, 1);
        } else this.$set(this.table[rowIndex], columnIndex, 0);
      }
    }
  }

  private syncTableSize(columns: number, rows: number): void {
    if (columns < this.maxColumnsCount) {
      this.columns = columns;
    }
    if (rows < this.maxRowsCount) {
      this.rows = rows;
    }
  }

  setTableToDefault(): void {
    this.table = [...defaultTable.map((array) => [...array])];
  }

  created(): void {
    this.setTableToDefault();
  }

  @Emit()
  click(): { cols: number; rows: number } {
    return { cols: this.previewColumns, rows: this.previewRows };
  }
}
