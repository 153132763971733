import { render, staticRenderFns } from "./link-address.vue?vue&type=template&id=4515c61a&scoped=true&"
import script from "./link-address.vue?vue&type=script&lang=ts&"
export * from "./link-address.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4515c61a",
  null
  
)

export default component.exports