

















import { Component, Vue, Emit } from "vue-property-decorator";
import colors from "./colors";

@Component
export default class Picker extends Vue {
  private colors: Array<string> = colors;

  @Emit("click")
  selectColor(color: string): string {
    return color;
  }
}
