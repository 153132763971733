













































import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import BubbleMenuContainer from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-container.vue";
import ToolbarButton from "@/app/shared/components/html-editor/toolbar/toolbar-button.vue";
import ToolbarGroup from "@/app/shared/components/html-editor/toolbar/toolbar-group.vue";
import TablePicker from "@/app/shared/components/html-editor/table/table-picker.vue";

@Component({
  components: {
    BubbleMenuContainer,
    ToolbarButton,
    ToolbarGroup,
    TablePicker,
  },
})
export default class BubbleMenuTable extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private deleteTable(): void {
    this.editor?.chain().focus().deleteTable().run();
  }

  //#region columns
  private addColumnAfter(): void {
    this.editor?.chain().focus().addColumnAfter().run();
  }
  private addColumnBefore(): void {
    this.editor?.chain().focus().addColumnBefore().run();
  }
  private deleteColumn(): void {
    this.editor?.chain().focus().deleteColumn().run();
  }
  //#endregion columns

  //#region rows
  private addRowAfter(): void {
    this.editor?.chain().focus().addRowAfter().run();
  }
  private addRowBefore(): void {
    this.editor?.chain().focus().addRowBefore().run();
  }
  private deleteRow(): void {
    this.editor?.chain().focus().deleteRow().run();
  }
  //#endregion rows

  private resize(): void {
    this.editor
      ?.chain()
      .focus()
      .updateAttributes("table", { width: "100%" })
      .run();
  }
}
