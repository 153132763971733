




















import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import { InfoType } from "@/app/shared/components/html-editor/info/info-extension";
import { lower } from "@/app/shared/filters/text-transform.filter";

@Component({
  filters: {
    lower,
  },
})
export default class Info extends Vue {
  @Prop({ required: true }) editor?: Editor;

  private infoTypes: Array<string> = Object.values(InfoType);

  private insertBlock(type: InfoType): void {
    this.editor?.chain().focus().addInfoBlock(type).run();
  }
}
