import {
  Command,
  Node,
  mergeAttributes,
  callOrReturn,
  getExtensionField,
} from "@tiptap/core";
import { TextSelection } from "prosemirror-state";
import { createLayoutBlock } from "@/app/shared/components/html-editor/layout/utils";

declare module "@tiptap/core" {
  interface Commands {
    layoutBlock: {
      insertLayoutBlock: (options?: { cols?: number }) => Command;
    };
  }
}

export interface LayoutBlockOptions {
  HTMLAttributes: Record<string, any>;
}

export const LayoutBlock = Node.create<LayoutBlockOptions>({
  name: "layoutBlock",
  group: "block",
  content: "layoutColumn+",
  isolating: true,
  layoutRole: "layoutBlock",

  addOptions() {
    return {
      HTMLAttributes: {},
      // View: LayoutView
    };
  },

  parseHTML() {
    return [{ tag: "div.layout-block" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "layout-block",
      }),
      0,
    ];
  },

  addCommands() {
    return {
      insertLayoutBlock:
        ({ cols = 2 } = {}) =>
        ({ tr, dispatch, editor }) => {
          const node = createLayoutBlock(editor.schema, cols);

          if (dispatch) {
            const offset = tr.selection.anchor + 1;

            tr.replaceSelectionWith(node)
              .scrollIntoView()
              .setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }

          return true;
        },
    };
  },

  extendNodeSchema(extension) {
    const context = {
      name: extension.name,
      options: extension.options,
    };

    return {
      layoutRole: callOrReturn(
        getExtensionField(extension, "layoutRole", context)
      ),
    };
  },
});
