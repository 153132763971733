


















import { Component, Vue, Emit, Prop } from "vue-property-decorator";

@Component
export default class ToolbarButton extends Vue {
  @Prop({ default: false, type: Boolean }) disabled = false;
  @Prop({ default: false, type: Boolean }) active!: boolean;
  @Prop({ default: "", type: String }) tooltip!: string;

  private get disableTooltip(): boolean {
    return !this.tooltip;
  }

  @Emit()
  click(): void {
    return;
  }
}
