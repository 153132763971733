import { Extension, mergeAttributes, Command } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands {
    textIndentApplied: {
      indent: () => Command;
      outdent: () => Command;
    };
  }
}

export const TextIndentExtension = Extension.create({
  name: "textIndent",

  defaultOptions: {
    types: ["heading", "paragraph"],
    step: 20,
    default: false,
    defaultValue: 0,
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          // use this attribute if you want active element (editor.isActive({ 'data-text-indent-applied': true }))
          "data-text-indent-applied": {
            default: this.options.default,
            parseHTML: (element) => {
              const textIndentApplied = element.style.marginLeft || "";
              return { "data-text-indent-applied": !!textIndentApplied };
            },
            renderHTML: (attributes) => {
              if (attributes["data-text-indent-applied"])
                return {
                  style: `margin-left: ${attributes["data-indent"]}px`,
                  "data-text-indent-applied": true,
                };
              return {};
            },
          },
          "data-indent": {
            default: this.options.defaultValue,
            parseHTML: (element) => {
              const indent = element.style.marginLeft
                ? parseInt(element.style.marginLeft)
                : this.options.defaultValue;
              return { "data-indent": indent };
            },
            renderHTML: (attributes) => {
              if (attributes["data-text-indent-applied"])
                return { "data-indent": parseInt(attributes["data-indent"]) };
              return {};
            },
          },
        },
      },
    ];
  },

  addCommands(): any {
    return {
      indent:
        () =>
        ({ commands, editor }: any) => {
          return this.options.types.forEach((type) => {
            const { "data-indent": dataIndent } = editor.getAttributes(type);
            const indent = dataIndent
              ? this.options.step + parseInt(dataIndent)
              : this.options.step;
            return commands.updateAttributes(type, {
              "data-indent": indent,
              "data-text-indent-applied": true,
            });
          });
        },
      outdent:
        () =>
        ({ commands, editor }: any) => {
          return this.options.types.forEach((type) => {
            const { "data-indent": dataIndent } = editor.getAttributes(type);
            if (dataIndent) {
              const newValue = parseInt(dataIndent) - this.options.step;
              if (newValue <= 0)
                return commands.updateAttributes(type, {
                  "data-text-indent-applied": false,
                  "data-indent": "",
                });
              return commands.updateAttributes(type, {
                "data-text-indent-applied": true,
                "data-indent": newValue,
              });
            }
          });
        },
      resetIndent:
        () =>
        ({ commands }: any) =>
          this.options.types.every((type) =>
            commands.resetAttributes(type, this.name)
          ),
    };
  },

  addKeyboardShortcuts() {
    return {
      "Alt-Ctrl-l": () => {
        if (
          this.editor.isActive("bulletList") ||
          this.editor.isActive("orderedList")
        ) {
          return this.editor.chain().focus().sinkListItem("listItem").run();
        }
        return this.editor.commands.indent();
      },
      "Alt-Ctrl-r": () => {
        if (
          this.editor.isActive("bulletList") ||
          this.editor.isActive("orderedList")
        ) {
          return this.editor.chain().focus().liftListItem("listItem").run();
        }
        return this.editor.commands.outdent();
      },
    };
  },
});
