import {
  Fragment,
  Node as ProsemirrorNode,
  Schema,
  NodeType,
} from "prosemirror-model";

export function getLayoutNodeTypes(schema: Schema): {
  [key: string]: NodeType;
} {
  if (schema.cached.layoutNodeTypes) {
    return schema.cached.layoutNodeTypes;
  }

  const roles: { [key: string]: NodeType } = {};

  Object.keys(schema.nodes).forEach((type) => {
    const nodeType = schema.nodes[type];

    if (nodeType.spec.layoutRole) {
      roles[nodeType.spec.layoutRole] = nodeType;
    }
  });

  schema.cached.layoutNodeTypes = roles;

  return roles;
}

export function createLayoutColumn(
  colType: NodeType,
  colContent?: Fragment | ProsemirrorNode | Array<ProsemirrorNode>
): ProsemirrorNode | null | undefined {
  if (colContent) {
    return colType.createChecked(null, colContent);
  }

  return colType.createAndFill();
}

export function createLayoutBlock(
  schema: Schema,
  colsCount: number,
  colContent?: Fragment | ProsemirrorNode | Array<ProsemirrorNode>
): ProsemirrorNode {
  const types = getLayoutNodeTypes(schema);
  const cols: ProsemirrorNode[] = [];

  for (let index = 0; index < colsCount; index += 1) {
    const col = createLayoutColumn(types.layoutColumn, colContent);
    if (col) cols.push(col);
  }
  return types.layoutBlock.createChecked(null, cols);
}
