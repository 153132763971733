














import { Component, Prop, Vue } from "vue-property-decorator";
import FileModel from "@/app/shared/models/file.model";

import ElHover from "@/app/shared/components/file-preview/el-hover.vue";
import ImageLoad from "@/app/shared/components/file-preview/image-load.vue";

@Component({
  components: {
    ElHover,
    ImageLoad,
  },
})
export default class FilePreview extends Vue {
  @Prop({ required: true, default: () => ({}) }) file!: FileModel;
  @Prop({ type: String, default: "" }) src!: string;
}
