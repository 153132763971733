







import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import IPopover from "@/app/shared/components/html-editor/popover/ipopover";
import Color from "./color.vue";

@Component({
  components: { Color },
})
export default class ColorPicker extends Vue {
  @Prop({ required: true }) editor?: Editor;

  $refs!: {
    popover: IPopover;
  };

  private selectColor(color: string): void {
    this.editor?.chain().focus().setColor(color).run();
    this.$refs.popover.doClose();
  }
}
