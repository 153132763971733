

















import { Component, Vue, Emit } from "vue-property-decorator";
import symbols from "./symbols";

@Component
export default class Symbols extends Vue {
  symbols: Array<string> = symbols;
  lineSize = 20;

  get arrayLength(): number {
    return Math.ceil(this.symbols.length / this.lineSize);
  }

  @Emit("click")
  selectSymbol(symbol: string): string {
    return symbol;
  }
}
