











































import { Component, Vue, Prop } from "vue-property-decorator";
import { Editor } from "@tiptap/vue-2";
import { ImageAttributes } from "@/app/shared/components/html-editor/image/extension";
import ToolbarGroup from "@/app/shared/components/html-editor/toolbar/toolbar-group.vue";
import ToolbarButton from "@/app/shared/components/html-editor/toolbar/toolbar-button.vue";
import BubbleMenuContainer from "@/app/shared/components/html-editor/bubble-menu/bubble-menu-container.vue";

enum ImageSize {
  small = 400,
  medium = 600,
  large = 800,
  original = "initial",
}

@Component({
  components: {
    ToolbarGroup,
    ToolbarButton,
    BubbleMenuContainer,
  },
})
export default class BubbleMenuImage extends Vue {
  @Prop({ required: true }) editor?: Editor;

  imageOptionsForm: ImageAttributes = new ImageAttributes();

  imageSize = ImageSize;

  updateImageSize(size: string): void {
    this.updateImageAttributes({ width: size });
  }

  changeImageOptions(): void {
    this.updateImageAttributes(this.imageOptionsForm);
  }

  updateImageAttributes(attributes: ImageAttributes): void {
    this.editor
      ?.chain()
      .focus()
      .updateAttributes("image", attributes)
      .blur()
      .run();
    this.setCurrentImageOptions(attributes);
  }

  private setCurrentImageOptions(options: ImageAttributes): void {
    Object.keys(options).forEach((key: string) => {
      // this.$set(
      //   this.imageOptionsForm,
      //   key,
      //   options[key as keyof ImageAttributes]
      // );
      this.imageOptionsForm[key as keyof ImageAttributes] =
        options[key as keyof ImageAttributes];
    });
  }

  private selectionUpdate(): void {
    if (this.editor?.isActive("image")) {
      const nodeAttributes = this.editor?.getAttributes("image");
      this.setCurrentImageOptions(nodeAttributes as ImageAttributes);
    }
  }

  created(): void {
    this.editor?.on("selectionUpdate", this.selectionUpdate);
    this.selectionUpdate();
  }

  beforeDestroy(): void {
    this.editor?.off("selectionUpdate", this.selectionUpdate);
  }
}
